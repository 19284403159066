@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: auto;
}
body {
  background-color: #0c1d34;
  margin: 0;
  font-family: 'acumin-pro', sans-serif;
}
body::-webkit-scrollbar-thumb {
  background-color: #6fe793;
}
button {
  font-family: 'acumin-pro', sans-serif;
}
a {
  font-family: 'acumin-pro', sans-serif;
  color: inherit;
  text-decoration: none;
}
